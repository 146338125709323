import React from 'react';
import './Admin.css'





import AdminLogin from './AdminLogin';






function Admin() {
    return (
        <div>
            <AdminLogin/>
           

        </div>
    )
}

export default Admin
