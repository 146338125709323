import React from 'react'

export default function EditOrder() {
  return (
    <div>
                <div className="div1">
  <div className="containerss">
    <h2>Edit Order</h2>
    <form>
      <label className="name">Order ID</label>
      <br />
      <input className="text" type="text" name placeholder="First Name" />
      <br />
      <label className="name">Status</label>
      <br />
      <input className="text" type="text" name placeholder="Last Name" />
      <br />
      <label className="name">Date/Time</label>
      <br />
      <input className="text" type="text" name placeholder="Father Name" />
      <br />
      <label className="name">	Invoices</label>
      <br />

      <input className="text" type="text" name placeholder="Father Name" />
      <br />

      <label className="name">Transaction</label>
      <br />

      <input className="text" type="text" name placeholder="Father Name" />
      <br />

      <label className="name">	Order Details</label>
      <br />

      <input className="text" type="text" name placeholder="Father Name" />
      <br />
     
    
      <button className="btn">UPDATE</button>
    

      
    </form></div></div>
    </div>
  )
}
