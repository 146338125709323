import React from 'react'

export default function EditUserProfile() {
  return (
    <div>
        <div className="div1">
  <div className="containerss">
    <h2>Edit User Profile</h2>
    <form>
      <label className="name">Id</label>
      <br />
      <input className="text" type="text" name placeholder="First Name" />
      <br />
      <label className="name">User Name</label>
      <br />
      <input className="text" type="text" name placeholder="Last Name" />
      <br />
      <label className="name">Email</label>
      <br />
      <input className="text" type="text" name placeholder="Father Name" />
      <br />
      <label className="name">Status</label>
      <br />

      <input className="text" type="text" name placeholder="Father Name" />
      <br />

      <label className="name">Transaction</label>
      <br />

      <input className="text" type="text" name placeholder="Father Name" />
      <br />
     
    
      <button className="btn">UPDATE</button>
    

      
    </form></div></div>
    </div>
  )
}
