import React from 'react'
import { Link } from "react-router-dom";

export default function Payment() {
    return (
        <div>
            <div className="div1">
                <div className="ccontainerss">
                    <h2>Payment System</h2>

                </div>
                
                </div>
                <Link to="/order"><button className='checkoutbtn'>Order</button></Link> 


        </div>
    )
}
